<template>
  <div>
    <select-plan
      :selected-product="form.product"
      :selected-product-term="form.productTerm"
      :currency="payload.currency"
      @productSelected="form.product = $event"
      @productTermSelected="form.productTerm = $event"
    />

    <b-field v-if="!premiumTask" class="has-text-right">
      <button
        type="button"
        class="button is-grey is-outlined"
        tabindex="-1"
        @click="skip"
      >
        Subscribe later
      </button>
    </b-field>

    <step-controls
      :enabled="formIsValid"
      @next="$emit('next')"
      @back="$emit('back')"
    />
  </div>
</template>

<script>
export default {
  name: "SelectPlanStep",
  components: {
    "select-plan": () => import("@shared/cart/_selectPlan")
  },
  props: {
    payload: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {
        product: this.payload.product,
        productTerm: this.payload.productTerm
      }
    };
  },
  computed: {
    premiumTask() {
      if (this.payload.task && this.payload.task.creditCost > 0) return true;
      return false;
    },
    formIsValid() {
      if (this.$_.isEmpty(this.form.productTerm)) return false;
      return true;
    }
  },
  watch: {
    "form.product"(product) {
      this.$emit("payload", { product });
    },
    "form.productTerm"(productTerm) {
      this.$emit("payload", { productTerm });
      this.$emit("next");
    }
  },
  methods: {
    skip() {
      this.$emit("payload", { productTerm: null });
      this.$emit("next");
    }
  }
};
</script>
